import { Directive, DirectiveBinding } from 'vue';
import { FeatureName } from '@/types/feature-names';
import useMainStore from '@/composables/use-main-store';
import Role from '@/enums/role';
import REQUIRED_ROLES from '@/constants/required-roles';
import { glsHasAnyRole } from '@gls-its-frontends/common/gls-roles';

const PermissionDirective: Directive<HTMLElement, FeatureName> = (element: HTMLElement, binding: DirectiveBinding<FeatureName>): void => {
    const { attributeStoreData } = useMainStore();

    const requiredRoles: Role[] = REQUIRED_ROLES[binding.value];
    const userRoles: Role[] = attributeStoreData.value?.role || [];

    const hasPermission: boolean = glsHasAnyRole(userRoles, requiredRoles);

    if (hasPermission) {
        return;
    }

    element.remove();
};

export default PermissionDirective;
