import '@/assets/styles/main.scss';
import de from '@/assets/i18n/de.json';
import en from '@/assets/i18n/en.json';
import { devEnvironment, prodEnvironment, setFeatureFlagsConfig, stageEnvironment } from '@/constants/env';
import PermissionDirective from '@/directives/v-permission';
import EnvironmentHostName from '@/enums/environment-hostname';
import { type EnvironmentConfig } from '@/types';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { getHostname, glsClearLocalStorageWhenAppVersionChange } from '@gls-its-frontends/common/gls-app-config';
import { configureDynatrace } from '@gls-its-frontends/common/gls-dynatrace';
import { glsLoaderPlugin } from '@gls-its-frontends/common/gls-loader';
import { glsCreateI18nInstance } from '@gls-its-frontends/common/gls-translate';
import { createPinia, type Pinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { type App, createApp, type Plugin } from 'vue';
import { glsVueQueryPluginOptions } from '@gls-its-frontends/common/gls-query';

const APP_TITLE: string = 'GLS Partner Portal';
const STAGE_DYNATRACE_RUM_APP_ID: string = 'ce6856498c72f4ce';
const PROD_DYNATRACE_RUM_APP_ID: string = '43b80643b8cf79f3';

const getAppTitle = (): string => {
    switch (getHostname()) {
        case EnvironmentHostName.PROD:
            return APP_TITLE;
        case EnvironmentHostName.LOCAL:
            return `${APP_TITLE} (LOCAL)`;
        case EnvironmentHostName.STAGE:
            return `${APP_TITLE} (STAGE)`;
        default:
            return `${APP_TITLE} (DEV)`;
    }
};

const setUpDynatrace = (): void => {
    switch (getHostname()) {
        case EnvironmentHostName.STAGE:
            configureDynatrace(STAGE_DYNATRACE_RUM_APP_ID);
            break;
        case EnvironmentHostName.PROD:
            configureDynatrace(PROD_DYNATRACE_RUM_APP_ID);
            break;
    }
};

const configureEnv = (): void => {
    const redirectURI: string = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
    }`;

    const envData = {
        VITE_AZURE_AD_AUTHORITY: 'https://login.microsoftonline.com/e6170c30-202d-4926-b525-b8b882873f3b',
        VITE_MSAL_REDIRECT_URI: redirectURI,
        VITE_MSAL_POSTLOGOUT_REDIRECT_URI: redirectURI
    };

    window.env = { ...envData, ...getEnvConfig() };
};

const getEnvConfig = (): EnvironmentConfig => {
    switch (getHostname()) {
        case EnvironmentHostName.STAGE:
            return stageEnvironment;
        case EnvironmentHostName.PROD:
            return prodEnvironment;
        default:
            return devEnvironment;
    }
};

/**
 * The app is wrapped into an async due to the necessity of fetching
 * the environment variable 'env' before other env-dependent imports happen.
 * Only the fetch function needs an async call, but as the dependent imports need
 * to execute afterwards, those have to be in await syntax as well.
 * The top level async is wrapped into an IFFE, otherwise the app wouldn't execute
 * on startup.
 */
(async (): Promise<void> => {
    await setFeatureFlagsConfig();
    glsClearLocalStorageWhenAppVersionChange(APP_VERSION);
    configureEnv();
    setUpDynatrace();
    document.title = getAppTitle();

    const { default: App } = await import('@/App.vue');
    const { default: router } = await import('@/routing/router');

    const pinia: Pinia = createPinia().use(piniaPluginPersistedstate);
    const i18n: Plugin = glsCreateI18nInstance({ de, en });

    const app: App<Element> = createApp(App);
    app.directive('permission', PermissionDirective)
        .use(glsLoaderPlugin)
        .use(pinia)
        .use(i18n)
        .use(router)
        .use(VueQueryPlugin, glsVueQueryPluginOptions)
        .mount('#app');
})();
