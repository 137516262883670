import Role from '@/enums/role';
import type { AttributeStoreData, UserType } from '@/types';
import { glsGetOidFromToken } from '@gls-its-frontends/common/gls-helpers';
import { defineStore } from 'pinia';
import { computed, ComputedRef, ref, type Ref, watch } from 'vue';

const mainStoreKey: string = 'main';

const mainStore = defineStore(
    mainStoreKey,
    () => {
        const attributeStoreData: Ref<AttributeStoreData | null> = ref(null);
        const accessToken: Ref<string | null> = ref(null);
        const username: Ref<string | null> = ref(null);
        const userId: Ref<string> = ref('GPP_Frontend');
        const userType: Ref<UserType | null> = ref(null);
        const tokenShouldBeRefreshed: Ref<boolean> = ref(false);

        const oid: ComputedRef<string | null> = computed((): string | null => glsGetOidFromToken(accessToken.value));
        const isPartner: ComputedRef<boolean> = computed((): boolean => userType.value === 'partner');
        const isCustomer: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.CUSTOMER_FRONTEND));
        const isAdmin: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.GLS_ADMIN));

        watch(oid, (newOid: string | null): void => {
            if (newOid === attributeStoreData.value?.oid) {
                return;
            }

            attributeStoreData.value = null;
        });

        return {
            attributeStoreData,
            oid,
            accessToken,
            username,
            userId,
            userType,
            isPartner,
            isCustomer,
            isAdmin,
            tokenShouldBeRefreshed
        };
    },
    {
        persist: {
            paths: ['attributeStoreData', 'tokenShouldBeRefreshed']
        }
    }
);

export default mainStore;
